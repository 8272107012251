import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import 'rxjs/add/operator/map';
import { Helper } from '../helper'
import { Observable } from 'rxjs/Rx';
import { Headers, RequestOptions } from '@angular/http';
@Injectable()
export class OnlineTestTemplateService {
    baseUrl = Helper.baseURL
    headers: any;
    options: any
    constructor(private http: HttpClient) {
        this.headers = new Headers({ 'Content-Type': 'text/plain' });
        this.options = new RequestOptions({ headers: this.headers });

    }
    getCourses(callerEmail) {

        return this.http.get(this.baseUrl + "/Course/getAllCourseForBatch?caller_email=" + callerEmail).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getExamTemplate(callerEmail) {

        return this.http.get(this.baseUrl + "/TestTemplate/getAllExamTemplate?caller_email=" + callerEmail).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getExamTemplateQuestions(callerEmail, id_exam_template) {

        return this.http.get(this.baseUrl + "/TestTemplate/getExamTemplateDetails?caller_email=" + callerEmail + "&id_exam_template=" + id_exam_template).map((res) => JSON.parse(JSON.stringify(res)));
    }



    removeExamTemplate(examTemplateId, callerEmail) {

        return this.http.get(this.baseUrl + "/TestTemplate/removeExamTemplate?caller_email=" + callerEmail + "&id_exam_template=" + examTemplateId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    changeStatus(examTemplateId, statusId, callerEmail) {

        return this.http.get(this.baseUrl + "/TestTemplate/changeStatus?caller_email=" + callerEmail + "&id_exam_template=" + examTemplateId + "&id_status=" + statusId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    addExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "/TestTemplate/addNewExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "/TestTemplate/editNewExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }
    getAllCoe(email) {
        return this.http.get(this.baseUrl + "/Coe/getAllActiveCoe?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllBatches(email) {
        return this.http.get(this.baseUrl + "/Batch/getAllActiveBatch?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSections(email) {
        return this.http.get(this.baseUrl + "/Section/getAllActiveSection?caller_email=" + email).map((res) => JSON.parse(JSON.stringify(res)));
    }

    updateExamTemplateSection(json) {
        return this.http.post(this.baseUrl + "/OnlineExam/updateExamTemplateSection", JSON.stringify(json)).map((res) => JSON.parse(JSON.stringify(res)));
    }
    addNewSectionExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "/TestTemplate/addNewSectionExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editSectionExamTemplate(json) {
        //.log(JSON.stringify(json))
        return this.http.post(this.baseUrl + "/TestTemplate/editSectionExamTemplate", json, this.options).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getAllSectionForTestTemplate(callerEmail, examTemplateId) {

        return this.http.get(this.baseUrl + "/TestTemplate/getAllSectionForTestTemplate?caller_email=" + callerEmail + "&id_exam_template=" + examTemplateId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    removeSectionExamTemplate(examTemplateSectionId, examTemplateId, callerEmail) {

        return this.http.get(this.baseUrl + "/TestTemplate/removeSectionExamTemplate?caller_email=" + callerEmail + "&id_exam_template=" + examTemplateId + "&id_exam_template_section_mapping=" + examTemplateSectionId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    getSectionScholar(callerEmail, sectionId, examTemplateId) {

        return this.http.get(this.baseUrl + "/TestTemplate/getSectionScholar?caller_email=" + callerEmail + "&id_section=" + sectionId + "&id_exam_template=" + examTemplateId).map((res) => JSON.parse(JSON.stringify(res)));
    }

    ScholarExamReset(email, examTemplateId, coeRollNumber) {
        return this.http.get(this.baseUrl + "/OnlineExam/ScholarExamReset?caller_email=" + email + "&id_exam_template=" + examTemplateId + "&coe_roll_number=" + coeRollNumber).map((res) => JSON.parse(JSON.stringify(res)));
    }

    ScholarArrayExamReset(json) {
        return this.http.post(this.baseUrl + "/OnlineExam/ScholarArrayExamReset", json).map((res) => JSON.parse(JSON.stringify(res)));
    }

    editRemainingTime(email, examTemplateId, scholarId, remainingTime) {
        //.log(JSON.stringify(json))
        return this.http.get(this.baseUrl + "/TestTemplate/updateExamRemainingTime?caller_email=" + email + "&id_exam_template=" + examTemplateId + "&scholar_id=" + scholarId + "&remaining_time=" + remainingTime).map((res) => JSON.parse(JSON.stringify(res)));
    }
}